<template>
    <div>
        <!--        <b-form-row>-->
        <!-- name -->
        <!--            <b-form-group-->
        <!--                    :id="locale + '.group-record.name'"-->
        <!--                    class="col-sm-12 col-md-6"-->
        <!--                    label-class="mb-0"-->
        <!--                    :label="$t('form.name')"-->
        <!--                    label-for="record.name"-->
        <!--                    description=""-->
        <!--            >-->
        <!--                <b-form-input-->
        <!--                        :id="locale + '.name'"-->
        <!--                        v-model="record.name"-->
        <!--                ></b-form-input>-->
        <!--            </b-form-group>-->
        <!--        </b-form-row>-->
        <!-- notes -->
        <b-form-group
            id="group-record.locale.notes"
            :label="$t('form.notes')"
            label-for="record.locale.notes"
            description=""

            class="col-sm-12 p-0"
            label-class="mb-0"
        >
            <vue-editor
                v-model="record.notes"
            ></vue-editor>
        </b-form-group>
    </div>
</template>

<script>
// @group Form.REGISTER
export default {
    name: "FormBankPurposeCodeLocale",
    components: {},
    props: {
        locale: '',
        record: {},
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
